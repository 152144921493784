import { useCurrentUser } from "../CurrentUserProvider";
import { useRealtimeConnectionState } from "./useRealtimeConnectionState";
import { useContext } from "react";
import { RealtimeContext } from "./RealtimeProvider";

export const useRealtimePresence = (channelName) => {
    const { currentUser } = useCurrentUser();

    useContext(RealtimeContext).usePresence?.(channelName);
    const connection = useRealtimeConnectionState(channelName);
    const { presenceData } = useContext(RealtimeContext).usePresenceListener?.(channelName) || { presenceData: [] };

    const members = presenceData
        .filter(member => member.clientId.split("-")[0] !== currentUser.id.toString())
        .map(member => ({
            id: member.clientId.split("-")[0],
            name: member.clientId.split("-")[1],
            status: member.action === "present" ? "online" : "offline"
        }));

    return { connection, members };
};
