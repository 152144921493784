import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";

import { useBaseInputStyles } from "./baseInputStyles";
import { T } from "../../../util/t";
import { Lock } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Tooltip } from "../../../elements/tooltip";

export const useSharedInputStyles = makeStyles(theme => ({
    wrapper: {
        "&&": {
            display: "flex",
            alignItems: "center",
            padding: 0,
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
            "&:focus-within:not(.error)": {
                borderColor: theme.palette.input.focus
            },
            "&:disabled": {
                opacity: .5,
            }
        },
    },
}));

export const useInputStyles = makeStyles(theme => ({
    adornment: {
        fontWeight: 700,
        backgroundColor: theme.palette.background.low,
        padding: theme.spacing(1, 2),
        display: "flex",
        alignItems: "center"
    },
    input: {
        appearance: "none",
        outline: "none",
        border: "none",
        lineHeight: "24px",
        padding: theme.spacing(1, 2),
        color: theme.palette.text.primary,
        width: "100%",
        "&::-webkit-outer-spin-button": {
            appearance: "none"
        },
        "&::-webkit-inner-spin-button": {
            appearance: "none"
        },
        MozAppearance: "textfield",
        "&::placeholder": {
            color: theme.palette.text.light
        },
        "&:disabled": {
            cursor: "default",
            backgroundColor: "transparent"
        },
    },
    locked: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(.5),
    }
}));
export const Input = ({ field, input, hasError }) => {
    const baseClasses = useBaseInputStyles();
    const classes = useInputStyles();
    const sharedClasses = useSharedInputStyles();
    const preventWheelInput = field.type === "number" || field.inputProps?.type === "number" ? e => e.target.addEventListener("wheel", function (e) { e.preventDefault(); }, { passive: false }) : null;

    return (
        <div className={classnames(baseClasses.baseInput, sharedClasses.wrapper, { error: hasError, disabled: field.restricted })}>
            {field.startAdornment && <label htmlFor={field.name} className={classes.adornment}><T>{field.startAdornment}</T></label>}
            <input
                id={field.name}
                className={classes.input}
                placeholder={field.placeholder}
                type={field.type}
                {...input}
                disabled={field.restricted}
                {...field.inputProps}
                onFocus={preventWheelInput}

                aria-disabled={field.restricted}
            />
            {field.restricted && <div className={classes.locked}>
                <Tooltip title={<T fieldName={field.label} contactEmail={field.contactEmail}>restricted_field</T>}>
                    <Typography variant="inherit">
                        <Lock />
                    </Typography>
                </Tooltip>
            </div>}
            {field.endAdornment && <span className={classes.endAdornment}>{field.endAdornment}</span>}
        </div>
    );
};
