export const stripName = name => name?.split(" ").filter(w => w).join(" ");
const parseProperties = (row, level, federationPropertiesByUuid) => Object.entries(row).reduce((properties, [key, value]) => {
    const path = `properties.${level}.`;
    if (key.indexOf(path) === -1) return properties;
    const uuid = key.slice(path.length);
    properties[uuid] = { ...federationPropertiesByUuid[uuid], value };
    return properties;
}, {});

export const eventResultDivisionsAndEntriesFromData = (data, organisation) => {
    const federationPropertiesByUuid = organisation.federationProperties.reduce((result, { __typename, ...property }) => {
        result[property.uuid] = property;
        return result;
    }, {});

    const allProperties = Object.keys(data.validData[0] || {}).filter(key => key.indexOf("properties.") > -1).map(key => key.slice(key.indexOf(".", "properties.".length) + 1));

    const [eventDivisions, entries] = data.validData.reduce(([eventDivisions, entries], row) => {
        const divisionName = stripName(row.division);
        if (!eventDivisions.includes(divisionName)) eventDivisions.push(divisionName);

        const registrationProperties = allProperties.length ? parseProperties(row, "registration", federationPropertiesByUuid) : undefined;
        const athleteProperties = allProperties.length ? parseProperties(row, "athlete", federationPropertiesByUuid) : undefined;

        const entry = {
            athlete: {
                name: row.name,
                dob: row.dob,
                properties: athleteProperties
            },
            team_name: row.teamName,
            bib: row.bib,
            rank: row.rank != null ? parseInt(row.rank) : undefined,
            event_division_id: row.eventDivisionId,
            properties: registrationProperties
        };

        if (entry) entries.push(entry);

        return [eventDivisions, entries];
    }, [[], []]);

    const [invalidEventDivisions] = data.invalidData.reduce(([invalidEventDivisions], row) => {
        const divisionName = stripName(row.division);
        if (!invalidEventDivisions.includes(divisionName)) invalidEventDivisions.push(divisionName);

        return [invalidEventDivisions];
    }, [[]]);

    return {
        entries,
        eventDivisions: eventDivisions,
        invalidEventDivisions: invalidEventDivisions,
    };
};
