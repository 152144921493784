import classnames from "classnames";
import { useCallback, useEffect } from "react";
import { string } from "yup";
import { useEvent } from "components/routes/events/id/EventProvider";
import { makeStyles } from "components/providers/makeStyles";
import { useFormKit } from "components/forms/FormKit";
import { convertToJS } from "components/eventDivisions/helpers";
import { useFormState, useWatch } from "react-hook-form";
import { useAsyncAthleteField } from "../forms/fields/SelectorField";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column"
        },
        "& > div": {
            flex: 1,
            marginBottom: theme.spacing(0),
            position: "relative",
            minWidth: "100%",
            "&:not(:last-of-type)": {
                marginBottom: theme.spacing(1)
            }
        },
        "& > div > div:last-of-type": {
            position: "absolute",
            textAlign: "right",
            top: theme.spacing(1),
            right: theme.spacing(8)
        }
    },
    submitting: {
        opacity: 0.5
    }
}));

export const AddAthleteForm = ({ onSubmit, teams: isTeams, eventDivisionId: currentEventDivisionId, alreadyAdded = [] }) => {
    const event = convertToJS(useEvent());
    const eventDivisions = event.eventDivisions.filter(ed => ed.eventDivisionId === currentEventDivisionId);
    const withDivision = eventDivisions.length > 0;
    const classes = useStyles();

    const athleteField = useAsyncAthleteField({
        name: "athlete_id",
        type: "creatableSearchSelect",
        alreadyAdded,
        isTeams,
    });

    const fields = [
        athleteField,
        withDivision && {
            name: "event_division_id",
            type: "searchSelect",
            validation: string().required("Required").nullable(),
            options: eventDivisions.map(ed => ({ label: ed.division.name, value: ed.id })),
            placeholder: "Enter division...",
        }
    ];

    const { control, handleSubmit, components } = useFormKit({ fields });
    const { isSubmitting } = useFormState({ control });

    const submit = useCallback(handleSubmit(values => {
        if (typeof values.athlete_id === "string") {
            values.athlete_name = values.athlete_id;
            delete values.athlete_id;
        } else {
            values.athlete_name = athleteField.options.find(({ value }) => value === values.athlete_id).label;
        }
        return onSubmit(values);
    }), [athleteField.options]);

    return (
        <form onSubmit={submit} className={classnames(classes.form, { [classes.submitting]: isSubmitting })}>
            <Fields
                control={control}
                fieldComponents={components}
                withDivision={withDivision}
                submit={submit}
            />
        </form>
    );
};

export const Fields = ({ control, fieldComponents, withDivision, submit }) => {
    const [athleteId, eventDivisionId] = useWatch({ name: ["athlete_id", "event_division_id"], control });

    useEffect(() => {
        if (!withDivision && athleteId) submit();
        if (withDivision && athleteId && eventDivisionId) submit();
    }, [withDivision, athleteId, eventDivisionId]);

    return fieldComponents;
};
