import { useCallback } from "react";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { string, array } from "yup";
import { makeStyles } from "components/providers/makeStyles";

import { usePropertyWidgetState } from "./PropertyWidget";
import { useFormKit } from "../../forms/FormKit";
import { T } from "../../util/t";
import { useLocalizationContext } from "components/providers/LocalizationProvider";

export const PropertyFormStep = ({ setStep, closeDialog, property }) => {
    const { type, values, setValues } = usePropertyWidgetState();
    const onSubmit = useCallback(values => {
        setValues(values);
        setStep(2);
    }, []);
    const onBack = useCallback(() => setStep(0), []);
    const { translate } = useLocalizationContext();

    const fields = [
        {
            name: "label",
            label: "Name of field",
            type: "text",
            inputProps: {
                disabled: !!property,
            },
            validation: string().required("You must name the field"),
            help: "This is how the field will display on the registration form and data exports, for example “T-shirt size” or “Membership ID”"
        },
        type === "select" && {
            name: "config.options",
            label: "Options users can select from",
            type: "creatableSearchSelect",
            placeholder: "Type to create an option",
            options: [],
            createLabel: value => translate("create_option:value", { value }),
            disabled: !!property,
            validation: array().min(1, "You must add at least one option").required("You must add at least one option"),
            autocompleteProps: {
                multiple: true
            }
        },
        {
            name: "required",
            label: "Is the answer required?",
            type: "radio",
            inputProps: {
                disabled: !!property,
            },
            parse: value => value === "true",
            options: [{ label: "Yes", value: true }, { label: "No", value: false }]
        }
    ];

    const { handleSubmit, components } = useFormKit({ fields, initialValues: { required: false, ...values } });
    return (
        <PropertyForm
            property={property}
            handleSubmit={handleSubmit(onSubmit)}
            fieldComponents={components}
            onBack={onBack}
            closeDialog={closeDialog}
        />
    );
};

const useStyles = makeStyles((theme, { disabled })  => ({
    dialogContent: {
        "& input, & input[type='radio'] + span, & .react-select--is-disabled": {
            cursor: disabled && "not-allowed",
        },
        "& .react-select--is-disabled": {
            pointerEvents: "all",
            "& .react-select__control": {
                pointerEvents: "none",
            }
        }
    }
}));

export const PropertyForm = ({ property, handleSubmit, fieldComponents, onBack, closeDialog }) => {
    const classes = useStyles({ disabled: !!property });

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent className={classes.dialogContent}>
                {fieldComponents}
            </DialogContent>
            <DialogActions className="sticky-dialog-actions">
                <Button onClick={onBack} className="left" startIcon={<ArrowBack/>}>
                    <T>Back</T>
                </Button>
                <Button variant="outlined" onClick={closeDialog}><T>Cancel</T></Button>
                <Button variant="contained" onClick={handleSubmit} endIcon={<ArrowForward/>}>
                    <T>Next</T>
                </Button>
            </DialogActions>
        </form>
    );
};
