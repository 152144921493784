import { useEffect, useState } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";
import classnames from "classnames";

import { LiveHeatsCards } from "./heats/card";
import { getHeatsAndEvent } from "./helpers";
import { schedulePositionSelector } from "./selectors";
import { T } from "../util/t";
import { LiveEmbed } from "./LiveEmbed";
import { useLocation } from "react-router-dom";
import logo from "assets/logos/LockupHorizontalPureWhite.svg";
import { GlobalStyles } from "@mui/material";
import { DetachableChannelProvider } from "../providers/Realtime/RealtimeProvider";

const upcomingStatuses = Immutable.List(["upcoming", "drawn", "scheduled"]);

const Message = ({ eventName, isFinished }) =>
    <div className="offline-message">
        <img src={logo} alt="Logo"/>
        <div className="text">
            {isFinished
                ? <T eventName={eventName}>broadcast_event_complete</T>
                : <T eventName={eventName}>broadcast_event_upcoming</T>
            }
        </div>
    </div>;

const useOverwriteCss = () => {
    const location = useLocation();
    useEffect(() => {
        const [, cssUrl] = location.search.match(/overwritecss=([^&]+)/) || [];
        if (!cssUrl) return;

        const head = document.getElementsByTagName("head")[0];
        const link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.id = "overwritecss-stylesheet";
        link.href = decodeURIComponent(cssUrl);
        head.appendChild(link);
    }, []);
};

export const BroadcastEvent = connect((state, props) => ({
    ...getHeatsAndEvent(state, props),
    schedulePosition: schedulePositionSelector(state, props)
}))(({  event, heatsLoaded, heats, schedulePosition, chromakey, match: { params: { id } } }) => {
    const [showScores, setShowScores] = useState(false);
    useOverwriteCss();

    useEffect(() => {
        const listener = e => {
            if (e.key === "s") setShowScores(showScores => !showScores);
        };
        document.addEventListener("keyup", listener);

        return () => {
            document.removeEventListener("keyup", listener);
        };
    }, []);

    const isFinished = schedulePosition !== 0 && heats.isEmpty();
    const isUpcoming = (schedulePosition === 0 && heats.isEmpty()) || upcomingStatuses.includes(event.get("status"));
    const renderMessage = isFinished || isUpcoming;

    return (
        <DetachableChannelProvider channelName={`event:${id}`}>
            <LiveEmbed id={id}/>
            {(!event.isEmpty() && heatsLoaded) ?
                <div id="broadcast" className={classnames("broadcast", { "broadcast-message": renderMessage, showScores, transparentBackground: !chromakey })}>
                    <GlobalStyles styles={{ body: { backgroundColor: "transparent" } }} />
                    {renderMessage ?
                        <Message isFinished={isFinished} eventName={event.get("name")}/>
                        :
                        <>
                            <div className="top-scores-header"><T>Top scores</T></div>
                            <LiveHeatsCards
                                heats={heats}
                                hideFinal={event.get("should_hide_finals")}
                                displayTimerIcon
                            />
                        </>
                    }
                </div> : null}
        </DetachableChannelProvider>
    );
});

export const BroadcastEventChromaKey = props => <BroadcastEvent {...props} chromakey />;
