import { useContext, useState } from "react";
import { RealtimeContext } from "./RealtimeProvider";

export const useRealtimeConnectionState = channelName => {
    const translateConnectionState = ablyState =>
        ablyState === "initialized" || ablyState === "connecting" ? "connecting" :
            ablyState === "connected" ? "connected" : "unavailable";

    const translateChannelState = channelState =>
        channelState === "initialized" || channelState === "attaching" ? "connecting" :
            channelState === "attached" ? "connected" : "unavailable";

    const ably = useContext(RealtimeContext).useAbly?.();
    const { channel } = useContext(RealtimeContext).useChannel?.(channelName) || {};
    const [connectionState, setConnectionState] = useState(ably?.connection.state ? translateConnectionState(ably?.connection.state) : "connecting");
    const [channelState, setChannelState] = useState(channel?.state ? translateChannelState(channel?.state) : "connecting");

    useContext(RealtimeContext).useConnectionStateListener?.(stateChange => {
        setConnectionState(translateConnectionState(stateChange.current));
    });
    useContext(RealtimeContext).useChannelStateListener?.(channelName, stateChange => {
        setChannelState(translateChannelState(stateChange.current));
    });

    if (connectionState === "connected" && channelState === "connected") return "connected";
    if (connectionState === "unavailable" || channelState === "unavailable") return "unavailable";
    return "connecting";
};
