import { PureComponent, useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Immutable from "immutable";
import dayjs from "dayjs";
import Creatable from "react-select/creatable";
import { components, createFilter } from "react-select";
import { VariableSizeList } from "react-window";
import { Box, Button, ButtonGroup, Divider, IconButton } from "@mui/material";
import { ArrowDropDown, Check, Clear } from "@mui/icons-material";

import { HelpTooltip } from "../elements/helpTooltip";
import { T } from "../util/t";
import { RowRenderer } from "components/virtualized-list/RowRenderer";
import { useResizeObserver } from "hooks/useResizeObserver";
import { ChipSelect } from "../layout/forms/fields/ChipSelect";
import { CustomDateInputField, DatePicker } from "components/layout/forms/fields/DateField";
import { ISO_DATE } from "utils/dates";
import { makeStyles } from "components/providers/makeStyles";
import { palette } from "../providers/theme/palette";
import { emailRegex } from "utils/validations";
import { SearchSelect } from "components/layout/forms/fields/Autocomplete/SearchSelect";
import { extraPropsForFieldType } from "./Field";

export const FormField = ({ type, showError, error = "", className, children }) =>
    <div className={classnames("formField", type, className, { error: showError })}>
        {children}
        {(showError && typeof error === "string") && <div className="error-message"><T>{error}</T></div>}
    </div>;

FormField.propTypes = {
    type: PropTypes.string,
    showError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    className: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const plainInput = (field) => {
    const { meta: { touched, error } } = field,
        showError = touched && error,
        { input, label, helpText, startAdornment, type, preventSubmit, preventPropagation, ...rest } = field,
        keyPress = preventSubmit ? e => {
            if (e.key === "Enter") e.preventDefault();
        } : null,
        click = preventPropagation ? e => e.stopPropagation() : null,
        preventWheelInput = type === "number" ? e => e.target.addEventListener("wheel", function (e) { e.preventDefault(); }, { passive: false }) : null;

    return (
        <FormField type={type} showError={showError} error={error}>
            {label &&
            <label htmlFor={input.name}>
                {label}
                {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
            </label>}
            {type === "textarea" ?
                <textarea id={input.name} {...input} onClick={click} {...rest}>
                    {input.value}
                </textarea> :
                <div className="input-wrapper">
                    {startAdornment && <div className="start-adornment">{startAdornment}</div>}
                    <input id={input.name} aria-label={input.name} {...input} type={type} onKeyPress={keyPress} onClick={click} {...rest} onFocus={preventWheelInput} />
                </div>
            }
        </FormField>
    );
};

export const noop = value => value;
const strictEqualsFinder = initial => ({ value }) => initial === value;

export const VariableMenuItem = ({ innerRef, item, onItemResize }) => {
    useResizeObserver(innerRef, onItemResize);
    return <MenuItem innerRef={innerRef} item={item} />;
};

export const MenuItem = ({ innerRef, item }) => <div ref={innerRef}>{item}</div>;

export const MenuList = ({ children, maxHeight, getStyles, theme, cx, getClassNames }) => {
    const listRef = useRef();
    const [itemsSize, setItemsSize] = useState({});
    const estimatedItemSize = 34;
    const [newItemHeight, setNewItemHeight] = useState(estimatedItemSize);

    let height = Math.min(children.length * estimatedItemSize, maxHeight);
    const numberOfVisibleItems = Math.floor(height / estimatedItemSize);

    // this is purely to improve performances: skip children.some when new item row is not visible
    if (children.length <= numberOfVisibleItems && children.some(({ props }) => props && props.data.__isNew__)) {
        height += newItemHeight - estimatedItemSize;
    }

    const onItemResize = useCallback(entries => setNewItemHeight(entries[0].contentRect.height), []);

    const itemData = useMemo(() => ({
        listRef,
        items: children,
        setItemsSize,
        renderItemComponent: (item, ref) => {
            // only need to listen to size changes for new items where the height can change if typing a long string
            return (item.props && item.props.data.__isNew__ && ref.current)
                ? <VariableMenuItem item={item} innerRef={ref} onItemResize={onItemResize} />
                : <MenuItem item={item} innerRef={ref} />;

        }
    }), [children]);

    return <>
        <div className="react-select__group">
            <components.GroupHeading getStyles={getStyles} theme={theme} cx={cx} getClassNames={getClassNames}>
                <T>Type or select</T>
            </components.GroupHeading>
        </div>

        {children.length ?
            <VariableSizeList
                ref={listRef}
                estimatedItemSize={estimatedItemSize}
                itemSize={index => itemsSize[index] || estimatedItemSize}
                itemData={itemData}
                height={height}
                itemCount={children.length}
            >
                {RowRenderer}
            </VariableSizeList> :
            children
        }
    </>;
};

const ClearIndicator = props => {
    const { innerProps: { ref, ...restInnerProps } } = props;
    return (
        <div {...restInnerProps} ref={ref}>
            <IconButton size="small"><Clear /></IconButton>
        </div>
    );
};

const IndicatorSeparator = () => {
    return <Box paddingY={1.5} height="100%"><Divider orientation="vertical" /></Box>;
};

const DropdownIndicator = props => {
    return <IconButton size="small"><ArrowDropDown /></IconButton>;
};

export class creatableSelect extends PureComponent {
    static propTypes = {
        selectOptions: PropTypes.shape({
            options: PropTypes.array,
            isMulti: PropTypes.bool,
            formatInitialLabel: PropTypes.func,
            optionsFinder: PropTypes.func,
            isValidNewOption: PropTypes.func,
            getNewOptionData: PropTypes.func,
        }),
        input: PropTypes.object,
        className: PropTypes.string,
        label: PropTypes.any,
        helpText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        meta: PropTypes.object,
    };

    state = { options: Immutable.List() };

    componentDidMount = () => this.setOptions();

    componentDidUpdate = prevProps => {
        if (!Immutable.fromJS(prevProps.selectOptions.options).equals(Immutable.fromJS(this.props.selectOptions.options))) {
            this.setOptions();
        }
    };

    setOptions = () => {
        const { input, selectOptions: { isMulti, formatInitialLabel = noop, optionsFinder = strictEqualsFinder } } = this.props;
        const value = Immutable.fromJS(input.value);
        let options = Immutable.List(this.props.selectOptions.options);

        const addOption = value => options = options.unshift({ label: "" + formatInitialLabel(value), value });

        if (!isMulti && value && !options.some(optionsFinder(value))) addOption(value);
        if (isMulti && value) value.forEach(v => v && !options.some(optionsFinder(v)) && addOption(v));

        this.setState({ options });
    };

    mapToFormValue = (isMulti, callback) =>
        isMulti ?
            callback(this.value ? Immutable.List(this.value).map(item => item.value) : Immutable.List()) :
            callback(this.value ? this.value.value : this.value);

    handleMenuOpen = () => this.isMenuOpen = true;
    handleMenuClose = () => this.isMenuOpen = false;

    recalculateMenuPosition = () => {
        // throttle event: https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
        if (!this.preventAnimationUpdate) {
            window.requestAnimationFrame(() => {
                this.forceUpdate(); // trigger a render to recalculate menu position
                this.preventAnimationUpdate = false;
            });
            this.preventAnimationUpdate = true;
        }
    };

    handleCloseMenuOnScroll = () => {
        if (!this.isMenuOpen) return false;
        this.recalculateMenuPosition();
        return false;
    };

    render = () => {
        const { className, label, helpText, input, meta: { touched, error }, selectOptions } = this.props,
            { options } = this.state,
            showError = touched && !!error,
            { isMulti,
                isValidNewOption = value => value, optionsFinder = strictEqualsFinder, getNewOptionData, ...rest
            } = selectOptions;

        return (
            <FormField type="creatable-select" className={className} showError={showError} error={error}>
                <label htmlFor={input.name}>
                    {label}
                    {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
                </label>
                <Creatable id={input.name}
                           theme={(theme) => ({
                               ...theme,
                               colors: {
                                   ...theme.colors,
                                   primary25: palette.secondary.light,
                                   primary: palette.secondary.lighter,
                                   primary50: palette.secondary.main,
                               },
                           })}
                           className={classnames("react-select", { "is-multi": isMulti })}
                           classNamePrefix="react-select"
                           {...input}
                           value={isMulti ?
                               Immutable.List(input.value || []).map(inputValue => options.find(optionsFinder(inputValue))).toArray() :
                               options.filter(optionsFinder(input.value)).toArray()}
                           onBlur={() => this.mapToFormValue(isMulti, input.onBlur)}
                           onChange={(value, actionMeta) => {
                               this.value = value;
                               (!isMulti && actionMeta.action === "create-option") && this.setState({ options: options.push(value) });
                               (isMulti && actionMeta.action === "create-option") && this.setState({ options: options.push(value[value.length - 1]) });
                               return this.mapToFormValue(isMulti, input.onChange);
                           }}
                           getNewOptionData={getNewOptionData}
                           isValidNewOption={(value, selectValue, selectOptions) =>
                               isValidNewOption(value, selectValue, selectOptions) &&
                               !options.some(optionsFinder(getNewOptionData ? getNewOptionData(value).value : value))
                           }
                           isClearable
                           isSearchable
                           {...rest}
                           {...{ isMulti, options }}
                           menuPortalTarget={document.getElementById("portal")}
                           styles={{
                               option: (baseStyles, state) => ({
                                   ...baseStyles,
                                   color: palette.primary.dark,
                               }),
                               menuPortal: base => ({ ...base, zIndex: 10000 }) }}
                           menuPosition="absolute"
                           menuPlacement="auto"
                           components={{ MenuList, ClearIndicator, IndicatorSeparator, DropdownIndicator }}
                           filterOption={createFilter({ ignoreAccents: false })}
                           onMenuOpen={this.handleMenuOpen}
                           onMenuClose={this.handleMenuClose}
                           closeMenuOnScroll={this.handleCloseMenuOnScroll}/>
            </FormField>
        );
    };
}

export const selectInput = ({ input, disabled, label, helpText, meta: { touched, error }, selectOptions, defaultOption }) =>
    <FormField type="select" showError={touched && error} error={error}>
        <label htmlFor={input.name}>
            {label}
            {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
        </label>
        <div className="select-wrapper">
            <select id={input.name} className="select-input" {...input} disabled={disabled}>
                {defaultOption && <option value="">{defaultOption}</option>}
                {selectOptions.map(option => <option value={option.value} key={`key-${option.value}`}>{option.label}</option>)}
            </select>
            <Box position="absolute" right={1} top={1} padding={1}>
                <ArrowDropDown />
            </Box>
        </div>
    </FormField>;

selectInput.propTypes = {
    selectOptions: PropTypes.oneOfType([PropTypes.instanceOf(Immutable.List), PropTypes.array]),
    input: PropTypes.object,
    label: PropTypes.any,
    helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object,
    disabled: PropTypes.bool,
    defaultOption: PropTypes.any,
};

export const chipsSelectInput = ({ input, label, helpText, meta: { touched, error }, selectOptions }) =>
    <FormField type="chips" showError={touched && error} error={error}>
        <label htmlFor={input.name}>
            {label}
            {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
        </label>
        <ChipSelect field={{ options: selectOptions }} input={input}/>
    </FormField>;

export const checkboxInput = ({ input, checked, disabled, label, helpText, className = "", meta: { touched, error } }) => {
    // eslint-disable-next-line eqeqeq
    const isChecked = typeof checked !== "undefined" ? checked : (input.value != false);
    return (
        <FormField type="checkbox" showError={touched && error} error={error}>
            <div className={`checkbox-input ${className}`}>
                <input id={input.name} {...input}
                       checked={isChecked}
                       disabled={disabled}
                       type="checkbox"/>
                <label htmlFor={input.name}>
                    {label}
                    {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
                </label>
                {isChecked && <Check />}
            </div>
        </FormField>
    );
};

checkboxInput.propTypes = {
    checked: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.any,
    meta: PropTypes.object,
    disabled: PropTypes.bool,
    defaultOption: PropTypes.any,
    className: PropTypes.any,
};

const useStyles = makeStyles((theme, { width = "60%" }) => ({
    wrapper: {
        width,
        minWidth: theme.spacing(24),
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }
    }
}));

export const dateInput = ({ input, meta: { touched, error }, label, width, ...rest }) => {
    const showError = touched && error;
    const showTime = rest.showTimeSelect;
    let selectedValue = input.value;
    const classes = useStyles({ width }); // eslint-disable-line react-hooks/rules-of-hooks

    return (
        <FormField type="date" showError={showError} error={error}>
            <label htmlFor={input.name}>{label}</label>
            <DatePicker id={input.name}
                        selected={input.value ? (showTime ? dayjs(input.value) : dayjs(input.value, ISO_DATE)).toDate() : null}
                        onChange={value => {
                            selectedValue = value ? (showTime ? dayjs(value).toJSON() : dayjs(value).format(ISO_DATE)) : value;
                            input.onChange(selectedValue);
                        }}
                        onBlur={() => input.onBlur(selectedValue)}
                        customInput={<CustomDateInputField {...input} />}
                        wrapperClassName={classes.wrapper}
                        hideClearButton={rest.inline}
                        input={input}
                        {...rest}/>
        </FormField>
    );
};

dateInput.propTypes = {
    input: PropTypes.object,
    label: PropTypes.any,
    meta: PropTypes.object,
};

export const radioInput = ({ label, helpText, yesNo, input, disabled, meta: { touched, error }, radioOptions }) => {
    const component = yesNo
        ? <ButtonGroup aria-label="Yes or no">
            {radioOptions.map((option, index) =>
                // eslint-disable-next-line eqeqeq
                <Button key={index} className={classnames({ selected: input.value == option.value })} onClick={() => input.onChange(option.value)}>
                    {option.label}
                </Button>
            )}
        </ButtonGroup>
        : <div className="radio-group multiple">
            {radioOptions.map((option, index) =>
                <div className="radio-input" key={index}>
                    <input type="radio" id={`${input.name}-${option.value}`} disabled={disabled} {...input} value={option.value}
                        // eslint-disable-next-line eqeqeq
                           checked={input.value == option.value}/>
                    <label htmlFor={`${input.name}-${option.value}`}>{option.label}</label>
                </div>
            )}
        </div>;

    return (
        <FormField type="radio" showError={touched && error} error={error}>
            <label>
                {label}
                {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
            </label>
            {component}
        </FormField>
    );
};

radioInput.propTypes = {
    input: PropTypes.object,
    helpText: PropTypes.string,
    label: PropTypes.any,
    meta: PropTypes.object,
    radioOptions: PropTypes.array,
};

export const SearchSelectInput = ({ input, disabled, label, helpText, meta: { touched, error }, ...rest }) => {
    const value = extraPropsForFieldType.searchSelect.format(input.value, rest);
    const onChange = value => input.onChange(extraPropsForFieldType.searchSelect.parse(value, rest));

    return (
        <FormField type="search-select" showError={touched && error} error={error}>
            <label htmlFor={input.name}>
                {label}
                {helpText && <HelpTooltip><T>{helpText}</T></HelpTooltip>}
            </label>
            <SearchSelect input={{ ...input, value, onChange }} field={rest} />
        </FormField>
    );
};

export const required = value => value && !/^\s*$/.test(value) ? undefined : "Required";

export const oneItemRequired = value =>Immutable.List(value).size === 0 && "Required";

export const mustBeEmail = value => value && emailRegex.test(value) ? undefined : "Email is invalid";

export const mustHaveLength = (min) => value => value && value.length >= min ? undefined : `Too short (minimum is ${min} characters)`;

export const hasMaxLength = (max) => value => value && value.length <= max ? undefined : `Max ${max} character limit reached`;

export const mustBeNumber = value => /^\d+$/.test(value) ? undefined : "Not a number";

export const numberOrEmpty = value => (value === "" || value == null || /^\d+$/.test(value)) ? undefined : "Not a number";

export const mustBeDecimal = value => /^\d+\.?\d*$/.test(value) ? undefined : "Not a number";

export const decimalOrEmpty = value => (value === "" || value == null || /^\d+\.?\d*$/.test(value)) ? undefined : "Not a number";

export const mustBeGreaterThanZero = value => /^\d+$/.test(value) && parseInt(value) > 0 ? undefined : "Must be greater than 0";

export const isValidRequired = value => !required(value);
