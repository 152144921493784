import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { T } from "../../../../util/t";
import { gql, useMutation } from "@apollo/client";
import { string } from "yup";
import { useFormKit } from "../../../../forms/FormKit";
import { SubmitButton } from "../../../forms/SubmitButton";
import { Notification } from "../../../notifications/Notification";

const UPDATE_ORGANISATION = gql`
  mutation updateOrganisationDocuseal($organisation: EditOrganisationInput!) {
    updateOrganisation(input: {organisation: $organisation}) {
      organisation {
        id
        docusealEnabled
      }
    }
  }
`;

export const ConfigureDocuseal = ({ organisationId, error, clearError, closeDialog }) => {
    const [updateOrganisation] = useMutation(UPDATE_ORGANISATION, { onCompleted: clearError });
    const onSubmit = organisation =>
        updateOrganisation({ variables: { organisation: { ...organisation, id: organisationId } } });

    const fields = [
        {
            name: "docusealApiKey",
            label: "DocuSeal API token",
            type: "text",
            helpTooltip: <>Your secure API X-Auth-Token. Find it in your DocuSeal <Link href="https://console.docuseal.co/api" underline="always" variant="inherit" target="_blank">API settings</Link>.</>,
            validation: string().required("Missing API token. Copy your unique API token from your DocuSeal account."),
        },
        {
            name: "docusealAdminEmail",
            label: "DocuSeal account email",
            helpTooltip: <>The email address used for your  DocuSeal account. Find it in your <Link href="https://docuseal.co/settings/profile" underline="always" variant="inherit" target="_blank">DocuSeal profile</Link>.</>,
            validation: string().trim().required("Email required").email("Try a complete email 😉"),
        }
    ];

    const { handleSubmit, components, control } = useFormKit({ fields });

    const formSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        return handleSubmit(onSubmit)(e);
    };

    return (
        <>
            <DialogTitle id="configure-docuseal-title" display="flex" alignItems="center" justifyContent="space-between" className="sticky-dialog-title">
                <Typography variant="h6" component="p">
                    <T>DocuSeal account verification</T>
                </Typography>
                <IconButton color="inherit" size="small" onClick={closeDialog}><Close/></IconButton>
            </DialogTitle>
            <form onSubmit={formSubmit}>
                <DialogContent className="sticky-dialog-content">
                    {error &&
                    <Box mb={2} textAlign="center">
                        <Notification type="error" text={
                            <>
                                <Typography variant="label1"><T>DocuSeal verification failed</T></Typography><br/>
                                <T>Check DocuSeal Pro is active and the below details are correct.</T>
                            </>
                        }/>
                    </Box>}
                    <T>To create a document for signing, please verify your DocuSeal account.</T>
                    <br/><br/>
                    <Typography variant="label1"><T>To complete set up:</T></Typography>
                    <Box component="ol" margin={0} paddingX={3} paddingY={0}>
                        <li><T>Check you have an active and paid <Link href="https://console.docuseal.co/plans" underline="always" variant="inherit" target="_blank">Pro</Link> plan with DocuSeal.</T></li>
                        <li><T>Enter your DocuSeal API token and account email address below.</T></li>
                    </Box>
                    <br/>
                    {components}
                    <Typography variant="caption">
                        <T>If you are not the admin for DocuSeal, please ask your admin to complete verification.</T><br/><br/>
                        <T>Need help? Contact <Link href="mailto:community@liveheats.com" underline="always" variant="inherit">community@liveheats.com</Link></T>
                    </Typography>
                </DialogContent>
                <DialogActions className="sticky-dialog-actions">
                    <Button onClick={closeDialog} sx={{ marginRight: "auto" }}><T>Close</T></Button>
                    <SubmitButton control={control} buttonLabel="Connect to DocuSeal" enableOnPristine/>
                </DialogActions>
            </form>
        </>
    );
};
