import { useCallback, useState } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import { Button, List, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { AttachMoney, Block, Cancel, ChangeHistory, Delete, Flag, Info, TouchApp, Warning } from "@mui/icons-material";

import { T } from "../../util/t";
import { makeStyles } from "components/providers/makeStyles";

export const modifierItemProps = {
    Interference: { name: "Interference", Icon: Warning },
    PriorityInterference: { name: "Priority Interference", Icon: Flag },
    Double: { name: "Double Whammy", Icon: AttachMoney },
    AbbPenalty: { name: "Team 5 Point Penalty", Icon: Flag },
    DNS: { name: "Did not start (DNS)", Icon: Warning },
    DNF: { name: "Did not finish (DNF)", Icon: Flag },
    DSQ: { name: "Disqualified (DSQ)", Icon: Block },
    DNI: { name: "Did not improve (DNI)", Icon: Info }
};
export const getModifiers = (heatConfig) => {
    const modifiers = heatConfig.get("modifiers", Immutable.List(["Interference", "PriorityInterference", "Double"]));
    return heatConfig.get("is_teams") ? modifiers.push("AbbPenalty") : modifiers;
};

export const TextAndIconMenuItem = ({ name, Icon, onClick }) =>
    <MenuItem onClick={onClick}>
        <ListItemIcon>
            <Icon/>
        </ListItemIcon>
        <T>{name}</T>
    </MenuItem>;

const useStyles = makeStyles(theme => ({
    button: {
        "&.selecting": {
            backgroundColor: theme.palette.border.focus
        }
    }
}));
export const ModifierMenu = ({ modifier, heatConfig, addModifier, buttonProps }) => {
    const [anchor, setAnchor] = useState();
    const classes = useStyles();
    const openMenu = useCallback(e => setAnchor(e.currentTarget), []);
    const closeMenu = useCallback(() => setAnchor(null), []);
    const cancel = useCallback(() => {
        addModifier(null);
        closeMenu();
    }, []);

    return (
        <>
            <Button className={classnames("modifiers-button", classes.button, { selecting: modifier })}
                    variant="outlined"
                    {...buttonProps}
                    onClick={openMenu}
                    startIcon={modifier ? <TouchApp/> : <ChangeHistory/>}
            >
                <T>{modifier ? "Select ride" : "Modifiers"}</T>
            </Button>

            <Menu anchorEl={anchor} open={!!anchor} variant="menu" onClose={cancel}>
                <ModifierList {...{ heatConfig, addModifier, closeMenu, cancel }}/>
            </Menu>
        </>
    );
};

export const ModifierList = ({ heatConfig, addModifier, closeMenu, cancel }) => {
    const removeModifier = useCallback(() => {
        addModifier("delete");
        closeMenu();
    }, []);

    return (
        <List>
            {getModifiers(heatConfig).map(modifier =>
                <ModifierMenuItem key={modifier} modifier={modifier} addModifier={addModifier} closeMenu={closeMenu}/>
            )}
            <TextAndIconMenuItem name="Remove modifier" Icon={Delete} onClick={removeModifier}/>
            <TextAndIconMenuItem name="Cancel" Icon={Cancel} onClick={cancel}/>
        </List>
    );
};

export const ModifierMenuItem = ({ modifier, addModifier, closeMenu }) => {
    const onClick = useCallback(() => {
        addModifier(modifier);
        closeMenu();
    }, []);
    const props = modifierItemProps[modifier];

    return <TextAndIconMenuItem {...props} onClick={onClick}/>;
};
