import { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Drawer, ListItemIcon, Menu, MenuItem, List } from "@mui/material";
import { Close, MoreHoriz, Print } from "@mui/icons-material";

import { useEventDivision } from "../EventDivisionProvider";
import { RedrawDialog } from "../../../../../../layout/eventDivision/RedrawDialog";
import { useIsMobile } from "hooks/useIsMobile";
import { T } from "../../../../../../util/t";
import { SaveAsTemplateWidget } from "../../../../../../layout/eventDivision/SaveAsTemplateWidget";

export const DrawHeaderActions = () => {
    const params = useParams();
    const { id } = params;
    const eventDivisionId = params.event_division_id || params.eventDivisionId;
    const eventDivision = useEventDivision();
    const eventDivisionDrawn = useRef(eventDivision.status === "drawn").current;

    const [anchor, setAnchor] = useState();
    const openMenu = e => setAnchor(e.currentTarget);
    const closeMenu = () => setAnchor(null);

    const print = useCallback(() => {
        setTimeout(window.print, 500); // give time to the drawer/menu to close
        closeMenu();
    }, []);

    const isMobile = useIsMobile();
    const actionList = (
        <List>
            <RedrawDialog inList eventId={id} eventDivisionId={eventDivisionId} drawn={eventDivisionDrawn} postRedraw={closeMenu}/>

            <MenuItem onClick={print}>
                <ListItemIcon><Print/></ListItemIcon>
                <T>Print</T>
            </MenuItem>

            <SaveAsTemplateWidget closeMenu={closeMenu}/>

            <MenuItem onClick={closeMenu}>
                <ListItemIcon>
                    <Close/>
                </ListItemIcon>
                <T>Close</T>
            </MenuItem>
        </List>
    );

    return (
        <>
            <Button className="more" onClick={openMenu} variant="outlined">
                <MoreHoriz/>
            </Button>
            {isMobile ?
                <Drawer anchor="bottom" open={!!anchor} onClose={closeMenu}>
                    {actionList}
                </Drawer> :
                <Menu anchorEl={anchor} open={!!anchor} variant="menu" onClose={closeMenu}>
                    {actionList}
                </Menu>
            }
        </>
    );
};
