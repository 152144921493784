import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { addPlaceModifier, removePlaceModifier } from "../../../../actions/heat";
import { List, Menu } from "@mui/material";
import { getModifiers, modifierItemProps, TextAndIconMenuItem } from "../ModifierMenu";
import { Delete } from "@mui/icons-material";

import { DSQMenuItem } from "./DSQMenuItem";
import { DSQEditMenuItem } from "./DSQEditMenuItem";

export const ModifierMenu = ({ anchor, closeMenu, dispatchParams, competitor, modifier, heatConfig }) => {
    const dispatch = useDispatch();

    const addModifier = useCallback((modifierType, notes = undefined) => {
        closeMenu();
        competitor.modifier = { type: `Modifiers::${modifierType}`, notes };
        return dispatch(addPlaceModifier({
            ...dispatchParams,
            parent_athlete_id: competitor.competitorId,
            athlete_id: competitor.athleteId,
            type: modifierType,
            notes
        })).then(res => competitor.modifier = res.data);
    }, []);
    const removeModifier = useCallback(() => {
        closeMenu();
        competitor.modifier = null;
        return dispatch(removePlaceModifier({
            ...dispatchParams,
            parent_athlete_id: competitor.competitorId,
            athlete_id: competitor.athleteId,
            modifier_id: modifier.id
        }));
    }, [modifier?.id]);

    return (
        <Menu anchorEl={anchor} open={!!anchor} variant="menu" onClose={closeMenu}>
            <List>
                {modifier ?
                    <>
                        {modifier.type === "Modifiers::DSQ" && <DSQEditMenuItem addModifier={addModifier} notes={modifier.notes}/>}
                        <TextAndIconMenuItem key="remove" name="Remove modifier" Icon={Delete} onClick={removeModifier}/>
                    </> :
                    getModifiers(heatConfig).map(modifierType => {
                        const { name, Icon } = modifierItemProps[modifierType];
                        return modifierType === "DSQ" ?
                            <DSQMenuItem key={modifierType} name={name} Icon={Icon} addModifier={addModifier}/> :
                            <TextAndIconMenuItem key={modifierType} name={name} Icon={Icon} onClick={() => addModifier(modifierType)}/>;
                    })
                }
            </List>
        </Menu>
    );
};
