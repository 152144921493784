export const name = {
    label: "Full name",
    key: "name",
    alternateMatches: ["full name", "Full Name"],
    fieldType: { type: "input" },
    example: "Jane Smith",
};
export const dob = {
    label: "Date of birth",
    key: "dob",
    alternateMatches: ["date of birth", "birth date", "DOB"],
    fieldType: { type: "input" },
    validations: [
        {
            rule: "regex",
            value: /^(\d{4}-\d{2}-\d{2})?$/,
            errorMessage: "Date of birth must be in yyyy-mm-dd format",
            level: "error"
        }
    ],
    example: "1970-01-01"
};
export const firstName = {
    label: "First name",
    key: "firstName",
    alternateMatches: ["first", "First name"],
    fieldType: {
        type: "input",
    },
    example: "Jane"
};
export const lastName = {
    label: "Last name",
    key: "lastName",
    alternateMatches: ["surname", "Last name", "Surname"],
    fieldType: {
        type: "input",
    },
    example: "Smith"
};
export const athleteProperties = properties => {
    return properties.map(property => ({
        label: property.label,
        key: `properties.athlete.${property.uuid}`,
        fieldType: { type: "input" }
    }));
};
