import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import * as EventActions from "../../actions/event";
import * as HeatActions from "../../actions/heat";
import * as NotificationActions from "../../actions/notification";

import { EventProvider } from "../routes/events/id/EventProvider";
import { manageEventPagesPath } from "../application";
import { eventSelector } from "./selectors";
import { AdminPageContainer } from "components/layout/page/AdminPageContainer";
import { OrganisationProvider } from "../providers/OrganisationProvider";
import { Event } from "models/Event";
import { convertToJS } from "../eventDivisions/helpers";
import { DetachableChannelProvider, useBindToChannelEvent } from "components/providers/Realtime/RealtimeProvider";

const EventSubscriptions = ({ dispatch, eventId, event, eventChannel }) => {
    useBindToChannelEvent({ channelName: eventChannel, skip: !event }, "event-updated", () => dispatch(EventActions.get(eventId)));
    useBindToChannelEvent({ channelName: eventChannel, skip: !event }, "heat-updated", ({ id: heatId }) => dispatch(HeatActions.get(eventId, heatId)));
    useBindToChannelEvent({ channelName: eventChannel, skip: !event }, "current-heats-changed", () => dispatch(EventActions.getCurrentHeats(eventId)));
    useBindToChannelEvent({ channelName: eventChannel, skip: !event }, "schedule-updated", () => {
        dispatch(EventActions.getSchedule(eventId));
        dispatch(EventActions.getCurrentHeats(eventId));
    });

    return null;
};

export const EventRoot = connect((state, props) => ({
    event: eventSelector(state, props),
}))(({ event, children, history, match }) => {
    const { params: { id } } = match;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(EventActions.getSchedule(id));
        dispatch(EventActions.get(id))
            .catch(e => {
                if (e.response && e.response.status === 404) {
                    dispatch(NotificationActions.warn("It looks like we can't find what you are looking for! Perhaps you were looking for one of our organisations?"));
                    return history.replace("/organisations");
                }
                throw e;
            });
    }, []);

    const eventChannel = `event:${id}`;
    const eventObject = !event.isEmpty() ? Event(convertToJS(event)) : undefined;

    return (
        <EventProvider value={{ event, eventChannel, eventObject }}>
            <OrganisationProvider value={{ organisation: convertToJS(event.get("organisation")) }}>
                <DetachableChannelProvider channelName={eventChannel}>
                    <EventSubscriptions event={eventObject} eventId={id} eventChannel={eventChannel} dispatch={dispatch}/>
                    {useRouteMatch(manageEventPagesPath)
                        ? <AdminPageContainer>{children}</AdminPageContainer>
                        : <main>{children}</main>}
                </DetachableChannelProvider>
            </OrganisationProvider>
        </EventProvider>
    );
});
