import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { PlayArrow, Stop, Undo } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { endHeat, startHeat, undoEndHeat } from "../../../actions/eventDirector";
import { LoadingButton } from "../../actions/loadingButton";
import { ConfirmationDialog } from "../../layout/dialogs/ConfirmationDialog";
import { T } from "../../util/t";

export const RacingToolbar = ({ eventId, heat, closeDrawer }) => {
    const dispatch = useDispatch();
    const heatId = heat.get("id");
    const onStartHeat = useCallback(() => dispatch(startHeat(eventId, heatId)), [eventId, heatId]);
    const onEndHeat = useCallback(() => dispatch(endHeat(eventId, heatId)), [eventId, heatId]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const openDialog = useCallback(() => setDialogOpen(true), [eventId, heatId]);
    const closeDialog = useCallback(() => {
        closeDrawer();
        setDialogOpen(false);
    }, [eventId, heatId]);
    const onUndoEndHeat = useCallback(() => dispatch(undoEndHeat(eventId, heatId)).then(closeDialog), [eventId, heatId]);

    return (
        <>
            {!heat.get("start_time") &&
            <LoadingButton action={onStartHeat} variant="contained" color="success" startIcon={<PlayArrow/>}>
                <T>Start</T>
            </LoadingButton>}

            {!!heat.get("start_time") && !heat.get("end_time") &&
            <LoadingButton action={onEndHeat} variant="contained" color="primary" startIcon={<Stop/>}>
                <T>End and confirm</T>
            </LoadingButton>}

            {heat.get("end_time") &&
            <ListItemButton variant="outlined" onClick={openDialog}>
                <ListItemIcon><Undo /></ListItemIcon>
                <ListItemText primary={<T>Undo end</T>}/>
            </ListItemButton>}

            <ConfirmationDialog
                open={dialogOpen}
                onClose={closeDialog}
                action={onUndoEndHeat}
                title="Undo end?"
                content="Caution - this will remove progressed athletes from later rounds, which might impact results if those rounds have been started. Alternatively, you can make edits on the previous screen without changing progression."
                buttonTitle="Proceed with undo"
            />
        </>
    );
};
