import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import * as EventActions from "../../actions/event";
import * as HeatActions from "../../actions/heat";
import { useBindToChannelEvent } from "../providers/Realtime/RealtimeProvider";

export const LiveEmbed = ({ id }) => {
    const dispatch = useDispatch();

    const loadData = useCallback(() => {
        dispatch(EventActions.getCurrentHeats(id));
        dispatch(EventActions.get(id));
    }, []);

    const loadHeat = useCallback(({ id: heatId }) => dispatch(HeatActions.get(id, heatId)), []);

    useEffect(() => {
        loadData();
    }, []);

    const eventChannel = `event:${id}`;
    useBindToChannelEvent(eventChannel, "event-updated", loadData);
    useBindToChannelEvent(eventChannel, "schedule-updated", loadData);
    useBindToChannelEvent(eventChannel, "heat-updated", loadHeat);
    useBindToChannelEvent(eventChannel, "current-heats-changed", loadData);

    return null;
};
