import { createContext, createElement, useContext, useState } from "react";
import { DialogTitle, Typography } from "@mui/material";

import { PropertyTypeStep } from "./PropertyTypeStep";
import { PropertyFormStep } from "./PropertyFormStep";
import { PropertyLevelStep } from "./PropertyLevelStep";
import { ResponsiveDialog } from "../../modal";
import { T } from "../../util/t";
import { makeStyles } from "components/providers/makeStyles";

export const PropertyWidget = ({ organisationId, dialogOpen, closeDialog, property, eventId, seriesId }) => (
    <ResponsiveDialog
            className="lh-dialog"
            open={dialogOpen}
            onClose={closeDialog}
            aria-labelledby="property-dialog-title">
        <PropertyDialogContent organisationId={organisationId} closeDialog={closeDialog} property={property} eventId={eventId} seriesId={seriesId}/>
    </ResponsiveDialog>
);

const PropertyContext = createContext({});
PropertyContext.displayName = "Property";
export const usePropertyWidgetState = () => useContext(PropertyContext);

const componentForStep = [PropertyTypeStep, PropertyFormStep, PropertyLevelStep];
const useStyles = makeStyles(theme => ({
    subtext: {
        marginTop: theme.spacing(1),
        color: theme.palette.text.subtitle,
    }
}));
export const PropertyDialogContent = ({ organisationId, closeDialog, property, eventId, seriesId }) => {
    const [step, setStep] = useState(0);
    const [type, setType] = useState(property?.type);
    const [values, setValues] = useState({ label: property?.label, required: !!property?.required, config: property?.config });
    const [level, setLevel] = useState(property?.level);
    const classes = useStyles();

    return (
        <>
            <DialogTitle id="property-dialog-title" className="sticky-dialog-title">
                <Typography variant="h6" component="div"><T>{property ? "View custom field": "Create custom field"}</T></Typography>
                <Typography variant="subtitle2" className={classes.subtext} component="div"><T step={step + 1} max={3}>step_x_of_max</T></Typography>
            </DialogTitle>
            <PropertyContext.Provider value={{ type, setType, values, setValues, level, setLevel, eventId, seriesId, organisationId }}>
                {createElement(componentForStep[step], { organisationId, setStep, closeDialog, property })}
            </PropertyContext.Provider>
        </>
    );
};
