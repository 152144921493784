import { useState } from "react";

export const useDialogState = (props = {}) => {
    const { disableBackdropClick } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const openDialog = () => setDialogOpen(true);
    const closeDialog = (e, reason) => {
        e?.stopPropagation?.();
        if (disableBackdropClick) {
            if (reason !== "backdropClick") {
                setDialogOpen(false);
                return true;
            } else {
                return false;
            }
        }
        else setDialogOpen(false);
    };

    return [dialogOpen, openDialog, closeDialog];
};
