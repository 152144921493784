import { bib, club, division, relayTeam, seed, rank, tagTeamName, teamName, registrationProperties } from "./competitorFields";
import { dob, firstName, lastName, name, athleteProperties } from "./athleteFields";

const slsFields = [name, division, club, relayTeam, dob, bib, seed, firstName, lastName];
const surfFields = [name, division, teamName, dob, bib, seed, tagTeamName, firstName, lastName];
const defaultEvenImportFields = [name, division, teamName, dob, bib, seed, firstName, lastName];

const resultSlsFields = [name, division, club, relayTeam, dob, bib, rank, firstName, lastName];
const resultSurfFields = [name, division, teamName, dob, bib, rank, tagTeamName, firstName, lastName];
const resultDefaultEvenImportFields = [name, division, teamName, dob, bib, rank, firstName, lastName];

const fieldsForSportType = {
    sls: slsFields,
    surf: surfFields,
};

const resultFieldsForSportType = {
    sls: resultSlsFields,
    surf: resultSurfFields,
};

export const getEventImportFields = (organisation, isResult) => {
    const fields = (isResult && [...resultFieldsForSportType[organisation.sportType] || resultDefaultEvenImportFields]) || [...fieldsForSportType[organisation.sportType] || defaultEvenImportFields];
    return [
        ...fields,
        ...registrationProperties(organisation.federationProperties.filter(property => property.level === "registration" )),
        ...athleteProperties(organisation.federationProperties.filter(property => property.level === "athlete" )),
    ];
};

