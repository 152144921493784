import Immutable from "immutable";

import { isNotNullOrUndefined, translateText } from "../../utils/utils";
import { mustBeNumber } from "./index";
import { T } from "../util/t";

export const validateNumberOption = value => !mustBeNumber(value);
const intNewOption = (value, label) => ({
    label: label,
    value: parseInt(value),
    __isNew__: true,
});
const setToAthletesLabel = value => <T value={value}>setToAthletesCreateLabel</T>;
const setToRidesLabel = value => <T value={value}>EditDivisionForm_ridesLabel</T>;

export const heatDurationSelectOptions = ({ defaultDuration, type, localization }) => {
    let options = [
        { label: "15", value: 15 },
        { label: "20", value: 20 },
        { label: "25", value: 25 },
        { label: "30", value: 30 },
        { label: "35", value: 35 },
        { label: "40", value: 40 },
    ];
    if (defaultDuration) {
        options.unshift({
            label: translateText({ text: "heatDurationDefault", localization, props: { type, defaultDuration } }),
            value: ""
        });
    }
    return {
        formatCreateLabel: value => <T value={value}>heatDurationLabel</T>,
        isValidNewOption: validateNumberOption,
        getNewOptionData: intNewOption,
        options
    };
};

export const heatSizeSelectOptions = ({ defaultSize, localization }) => {
    const options = [
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 }
    ];
    if (defaultSize) {
        options.unshift({
            label: translateText({ text: "divisionDefault", localization, props: { defaultSize } }),
            value: ""
        });
    }

    return {
        formatCreateLabel: setToAthletesLabel,
        isValidNewOption: value => parseInt(value) !== 1 && /\d+/.test(value),
        getNewOptionData: intNewOption,
        options
    };
};

export const roundSizeSelectOptions = {
    formatCreateLabel: value => <T value={value}>setToRoundsCreateLabel</T>,
    isValidNewOption: validateNumberOption,
    getNewOptionData: intNewOption,
    options: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 }
    ]
};

const progressionSelectFunctions = ({ localization }) => ({
    isValidNewOption: validateNumberOption,
    optionsFinder: initial => ({ value }) => initial ? initial.equals(value) : initial === value,
    formatInitialLabel: value => translateText({ text: "x_progress", localization, props: { x: value.getIn([0, "max"]) } }),
    getNewOptionData: value => ({
        label: translateText({ text: "x_progress", localization, props: { x: value } }),
        value: Immutable.fromJS([{ max: parseInt(value) }]),
        __isNew__: true,
    })
});

export const divisionRoundBasedProgressionSelectOptions = ({ localization }) => ({
    ...progressionSelectFunctions({ localization }),
    options: [
        { label: "50%", value: Immutable.fromJS([{}]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 0 } }), value: Immutable.fromJS([{ max: 0 }]) },
    ]
});

export const roundProgressionSelectOptions =  ({ localization }) => ({
    ...progressionSelectFunctions({ localization }),
    options: [
        { label: "50%", value: Immutable.fromJS([{}]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 0 } }), value: Immutable.fromJS([{ max: 0 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 1 } }), value: Immutable.fromJS([{ max: 1 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 2 } }), value: Immutable.fromJS([{ max: 2 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 3 } }), value: Immutable.fromJS([{ max: 3 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 4 } }), value: Immutable.fromJS([{ max: 4 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 5 } }), value: Immutable.fromJS([{ max: 5 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 6 } }), value: Immutable.fromJS([{ max: 6 }]) },
    ]
});

export const inRoundProgressionSelectOptions = ({ localization, round, divisionDefault }) => ({
    ...progressionSelectFunctions({ localization }),
    options: [
        { label: translateText({ text: "divisionDefault", localization, props: { defaultSize: divisionDefault } }), value: "" },
        { label: "50%", value: Immutable.fromJS([{}]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 0 } }), value: Immutable.fromJS([{ max: 0 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 1 } }), value: Immutable.fromJS([{ max: 1 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 2 } }), value: Immutable.fromJS([{ max: 2 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 3 } }), value: Immutable.fromJS([{ max: 3 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 4 } }), value: Immutable.fromJS([{ max: 4 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 5 } }), value: Immutable.fromJS([{ max: 5 }]) },
        { label: translateText({ text: "x_progress", localization, props: { x: 6 } }), value: Immutable.fromJS([{ max: 6 }]) },
        { label: translateText({ text: "2nd chance", localization }), value: Immutable.fromJS([{ to_round: round + 2 }, { to_round: round + 1 }]) }
    ]
});

export const runProgressionSelectOptions =  ({ localization }) => ({
    ...progressionSelectFunctions({ localization }),
    options: [
        { label: translateText({ text: "No progression: all runs use same start list", localization }), value: "" },
        { label: "50%", value: Immutable.fromJS([{}]) }
    ]
});

export const inRoundRunProgressionSelectOptions = ({ localization, divisionDefault }) => ({
    ...progressionSelectFunctions({ localization }),
    options: [
        { label: translateText({ text: "divisionDefault", localization, props: { defaultSize: divisionDefault } }), value: "" },
        { label: translateText({ text: "No progression: all runs use same start list", localization }), value: Immutable.fromJS([]) },
        { label: "50%", value: Immutable.fromJS([{}]) }
    ]
});

export const athletesPerTeamSelectOptions = {
    formatCreateLabel: setToAthletesLabel,
    isValidNewOption: validateNumberOption,
    getNewOptionData: intNewOption,
    options: [
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 }
    ]
};

export const countingRidesSelectOptions= localization => ({
    formatCreateLabel: setToRidesLabel,
    isValidNewOption: validateNumberOption,
    getNewOptionData: intNewOption,
    options: [
        { label: translateText({ text: "All rides", localization }), value: "" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 }
    ]
});

export const athleteRidesLimitSelectOptions = localization => ({
    formatCreateLabel: setToRidesLabel,
    isValidNewOption: validateNumberOption,
    getNewOptionData: intNewOption,
    options: [
        { label: translateText({ text: "Unlimited", localization }), value: "" },
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "12", value: 12 },
        { label: "15", value: 15 },
    ]
});

export const jerseySelectOptions = localization => ({
    isValidNewOption: () => false,
    isMulti: true,
    isClearable: false,
    components: { DropdownIndicator: null },
    options: [
        { value: "red", label: translateText({ text: "red", localization }) },
        { value: "white", label: translateText({ text: "white", localization }) },
        { value: "yellow", label: translateText({ text: "yellow", localization }) },
        { value: "blue", label: translateText({ text: "blue", localization }) },
        { value: "green", label: translateText({ text: "green", localization }) },
        { value: "pink", label: translateText({ text: "pink", localization }) },
        { value: "black", label: translateText({ text: "black", localization }) },
        { value: "orange", label: translateText({ text: "orange", localization }) },
        { value: "purple", label: translateText({ text: "purple", localization }) }
    ],
});

export const maxRideScoreSelectOptions = {
    formatCreateLabel: value => <T value={value}>EventForm_judgeScale</T>,
    isValidNewOption: validateNumberOption,
    getNewOptionData: intNewOption,
    options: [
        { label: "10", value: 10 },
        { label: "100", value: 100 }
    ]
};

export const customProgressionProgressSelectOptions = ({ progression, index, isFirstRuleFifty, localization }) => {
    const from = progression
        .slice(0, index)
        .filter(isNotNullOrUndefined)
        .reduce((prev, current) => prev + (current.get("max") || 0), 1);

    const getLabel = value => parseInt(value) === from
        ? translateText({ text: "x_progression_label_from", localization, props: { x: from } })
        : translateText({ text: "progression_label", localization, props: { from, to: value } });

    const getInitialLabel = value => getLabel(value + from - 1);

    let options = ([...new Array(3)]).map((v, index) => ({
        label: getLabel(index + from),
        value: index + 1,
    }));

    const onlyAllRemainingAllowed = index === 1 && isFirstRuleFifty(progression);

    const isValidNewOption = (inputValue, selectValue, selectOptions) => {
        if (!validateNumberOption(inputValue)) return false;
        if (parseInt(inputValue) < from) return false;
        if (onlyAllRemainingAllowed) return false;
        return !selectOptions.some(option => option.value + from - 1 === parseInt(inputValue));
    };

    const noOptionsMessage = (data) => {
        if (onlyAllRemainingAllowed || !validateNumberOption(data.inputValue)) {
            return translateText({ text: "Option unavailable", localization });
        }
        return translateText({ text: "x_progression_already_set", localization, props: { x: data.inputValue } });
    };

    const commonStuff = {
        noOptionsMessage,
        isValidNewOption,
        formatInitialLabel: getInitialLabel,
        getNewOptionData: (value) => ({
            label: getLabel(value),
            value: parseInt(value) - from + 1,
            __isNew__: true,
        }),
    };

    if (index === 0) options.unshift({ label: translateText({ text: "50%", localization }), value: "" });
    if (onlyAllRemainingAllowed) options = [{ label: translateText({ text: "All remaining", localization }), value: "" }];

    return { ...commonStuff, options };
};

// eslint-disable-next-line eqeqeq
const validateNewResultsToCountOption = value => !validateNumberOption(value) || value == 0 ? false : true;

export const resultsToCountOptions = (localization, disabled) => ({
    formatCreateLabel: value => <T value={value}>setToTopResultsLabel</T>,
    isValidNewOption: validateNewResultsToCountOption,
    getNewOptionData: intNewOption,
    isDisabled: disabled,
    options: [
        { label: translateText({ text: "All results", localization }), value: "" },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 },
        { label: "7", value: 7 },
        { label: "8", value: 8 }
    ]
});
