import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useRecordNextPlace } from "../../../../layout/heat/edit/useRecordNextPlace";
import { updateAthleteHeat } from "../../../../../actions/judge";
import { get as getHeat } from "../../../../../actions/heat";
import { TeamSelector } from "../../../../layout/eventDivision/TeamSelector";

export const AddToTeamAndRecordButton = ({ competitors, heat, nextPlace, resetCompetitors, athlete, tagId, setDisappearingEvent }) => {
    const { id: eventId } = useParams();
    const heatId = heat.get("id");
    const dispatch = useDispatch();
    const recordNextPlace = useRecordNextPlace({ heatId, nextPlace });
    const teams = heat.get("athletes").valueSeq().toArray().map(team => ({
        id: team.get("athlete_heat_id"),
        competitorId: team.get("id"),
        athlete: {
            name: team.get("name")
        },
        athletes: team.get("athletes")?.map(athlete => ({ order: athlete.get("order") }))?.toJS() || []
    }));
    const teamAppraisal = heat.getIn(["config", "team_config", "appraisal_level"]) === "team";

    const addAthleteAndRecord = useCallback(teamId => {
        const team = teams.find(t => "" + t.id === teamId);
        const competitor = teamAppraisal && competitors.find(c => c.competitorId === team.competitorId);

        const maxOrder = Math.max(...team.athletes.map(a => a.order));
        return dispatch(updateAthleteHeat(eventId, heatId, team.id, {
            team_members_attributes: [{
                athlete_id: athlete.id,
                physical_tag_id: tagId,
                order: maxOrder < 0 ? 0 : maxOrder + 1
            }]
        }))
            .then(() => {
                return competitor?.place
                    ? Promise.resolve()
                    : recordNextPlace(team.competitorId, teamAppraisal ? team.competitorId : athlete.id);
            })
            .then(() => dispatch(getHeat(eventId, heatId, false)))
            .then(() => {
                resetCompetitors();
                setDisappearingEvent({ type: "success" });
            });
    }, [recordNextPlace, competitors, athlete, tagId]);

    return <TeamSelector onSubmit={addAthleteAndRecord} teams={teams}/>;
};
