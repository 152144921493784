import Immutable from "immutable";
import { Check, ChevronRight } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";
import { useCallback, useEffect } from "react";

import { useIsMobile } from "hooks/useIsMobile";
import { Checkbox } from "../forms/fields/Checkbox";
import { useTableStyles } from "../commonStyles/tableStyles";
import { CreatePropertyWidget } from "./CreatePropertyWidget";
import { T } from "../../util/t";
import { usePrevious } from "../../../hooks/usePrevious";
import { PropertyWidget } from "./PropertyWidget";
import { useDialogState } from "../../../hooks/useDialogState";
import { FormSection } from "../forms/FormSection";
import { Property } from "models/Property";
import { Typography } from "@mui/material";

const addCaptureField = (array, property, index, size) => {
    if (index !== undefined) array.splice("custom_fields_registrations_attributes", index, 1, property.delete("_destroy").set("sort_order", index));
    else array.push("custom_fields_registrations_attributes", property.set("sort_order", size));
};

const removeCaptureField = (array, index, captureField) => {
    array.splice("custom_fields_registrations_attributes", index, 1, captureField.merge({ _destroy: true }));
};

const systemProps = [
    { label: "Athlete name", type: "text", level: "athlete", required: true },
    { label: "Email", type: "text", level: "user", required: true },
    { label: "Contact number", type: "text", level: "user", required: true },
    { label: "Date of birth", type: "date", level: "athlete", required: true },
];
const useStyles = makeStyles(theme => ({
    label: {
        "&&": {
            paddingLeft: theme.spacing(2)
        }
    },
    tableMargin: {
        marginTop: theme.spacing(2)
    }
}));
export const PropertiesTable = ({ organisationId, formState, array, properties, ownPropertiesId, event, seriesId }) => {
    const classes = { ...useTableStyles(), ...useStyles() };
    const disabled = formState.getIn(["registration_options", "managed_by_parent"]);
    const isMobile = useIsMobile();
    const previousProperties = usePrevious(properties);
    const captureFields = formState.get("custom_fields_registrations_attributes") || Immutable.List();

    const findCaptureField = useCallback(property => captureFields
        .findEntry(captureProperty => captureProperty.get("uuid") === property.uuid) || [], [captureFields]);

    useEffect(() => {
        if (previousProperties?.length > properties.length) {
            const uuids = properties.map(p => p.uuid);
            const archivedProperty = previousProperties.find(property => uuids.indexOf(property.uuid) === -1);
            const [captureFieldIndex, captureField] = findCaptureField(archivedProperty);
            if (captureFieldIndex > -1) removeCaptureField(array, captureFieldIndex, captureField);
        } else if (previousProperties?.length < properties.length) {
            const uuids = previousProperties?.map(p => p.uuid);
            const newProperty = properties.find(property => uuids?.indexOf(property.uuid) === -1);
            if (newProperty) addCaptureField(array, Immutable.fromJS(newProperty), undefined, captureFields.size);
        }
    }, [properties, previousProperties, findCaptureField]);

    return (
        <FormSection
            borderless
            title="Registration form"
            subtitle="Manage the fields captured in registration and included in data exports"
            disabled={disabled}
        >
            <CreatePropertyWidget disabled={disabled} organisationId={organisationId}/>
            <table className={`${classes.table} ${classes.tableMargin}`}>
                <thead>
                    <tr>
                        <th><T>Active</T></th>
                        <th className={classes.label}><T>Field</T></th>
                        {!isMobile &&
                        <>
                            <th><T>Type</T></th>
                            <th><T>Category</T></th>
                            <th><T>Required</T></th>
                        </>}
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {systemProps.map(property => <PropertyRow key={property.label} property={property} checked disabled/>)}

                    {properties.map(property => {
                        const [captureFieldIndex, captureField] = findCaptureField(property);
                        const checked = captureFieldIndex > -1 && !captureField.get("_destroy");
                        const onChange = e => {
                            return e.target.checked ?
                                addCaptureField(array, captureField || Immutable.fromJS(property), captureFieldIndex, captureFields.size) :
                                removeCaptureField(array, captureFieldIndex, captureField);
                        };

                        return (
                            <PropertyRow
                                key={property.uuid}
                                property={property}
                                organisationId={organisationId}
                                eventId={event?.get("id")}
                                seriesId={seriesId}
                                disabled={disabled}
                                checked={checked}
                                onChange={onChange}
                                description={captureField?.getIn(["config", "description"]) || property.config?.description}
                                isOwnProperty={ownPropertiesId.indexOf(property.uuid) > -1}/>
                        );
                    })}
                </tbody>
            </table>
        </FormSection>
    );
};

const propertyTypeTitles = {
    text: "Free text or number",
    select: "Drop down",
    checkbox: "Check box",
    date: "Date picker"
};
const useRowStyles = makeStyles((theme, { enabled }) => ({
    centered: {
        textAlign: "center"
    },
    label: {
        "&&": {
            paddingLeft: theme.spacing(2)
        }
    },
    checkbox: {
        "&&": {
            padding: theme.spacing(1.5, 0, 1)
        }
    },
    sameSize: {
        "&&": {
            width: "25%",
            [theme.breakpoints.down("md")]: {
                width: "50%"
            }
        },
    },
    chevron: {
        textAlign: "right",
        "&&": {
            paddingRight: theme.spacing(1)
        }
    },
    row: {
        cursor: enabled && "pointer"
    }
}));

const isStagingEnvironment = window.location.href.includes("staging.liveheats");
export const usasaOrgId = isStagingEnvironment ? "4231" : "316";
export const usasaPropertyUUIDS = isStagingEnvironment
    ? ["5e3e56cf-f153-4595-84e8-346fd3f6f4b3", "a79f9680-12f6-4d9f-b089-fa3bd0e5a2bc"]
    : ["1ab63fa2-6497-4f4b-b4f7-8b242d9aa58f", "bbe51799-4952-4455-98d4-ce0c102a18eb"];

export const PropertyRow = ({ disabled, property, description, organisationId, eventId, seriesId, checked, onChange, isOwnProperty }) => {
    const isMobile = useIsMobile();
    const isCustomProperty = !Property(property).isSystemProperty();

    /*
        AAH: isReadOnlyProperty are a quick fix for the delay of https://app.clickup.com/t/86cwdenrp
        Remove when the card is picked up.
    */
    const isReadOnlyPropertyUUIDs = usasaPropertyUUIDS.includes(property.uuid);
    const isReadOnlyOrgId = usasaOrgId == organisationId; // eslint-disable-line eqeqeq
    const isReadOnlyProperty = isReadOnlyPropertyUUIDs && isReadOnlyOrgId;
    const enabled = isOwnProperty && !isReadOnlyProperty && !disabled && isCustomProperty;
    const classes = useRowStyles({ enabled });
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    const handleCheckboxClick = useCallback(e => e.stopPropagation(), []);

    return (
        <>
            {enabled && <PropertyWidget
                organisationId={organisationId}
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                property={property}
                eventId={eventId}
                seriesId={seriesId}
            />}
            <tr onClick={enabled ? openDialog : undefined} className={classes.row}>
                <td className={`${classes.centered} ${classes.checkbox}`}>
                    <Checkbox input={{ onChange, value: checked, disabled: disabled || isReadOnlyProperty, onClick: handleCheckboxClick }}/>
                </td>
                <td className={`${classes.label} ${classes.sameSize}`}>
                    {property.label}
                    {description && <Typography variant="caption" component="div" color="text.secondary">{description}</Typography>}
                </td>
                {!isMobile &&
                <>
                    <td className={classes.sameSize}><T>{propertyTypeTitles[property.type]}</T></td>
                    <td className={classes.sameSize}><T>{property.level}</T></td>
                    <td className={classes.centered}>{property.required && <Check fontSize="small"/>}</td>
                </>}
                <td className={`${!isMobile && classes.sameSize} ${classes.chevron}`}>
                    {enabled && <ChevronRight/>}
                </td>
            </tr>
        </>
    );
};
