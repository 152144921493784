import { Button, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useHasPendingRides } from "./useHasPendingRides";
import { RacingToolbar } from "./RacingToolbar";
import { ApproveAllRidesButton } from "./ApproveAllRidesButton";
import { LoadingModalWithTrigger } from "../../layout/heat/HeatModal";
import { T } from "../../util/t";
import { EditHeatToolbarSecondaryMenu } from "./EditHeatToolbarSecondaryMenu";
import { useCallback } from "react";
import { FormatListNumbered, Print } from "@mui/icons-material";
import { RacingUndoStart } from "./RacingUndoStart";
import { useDialogState } from "hooks/useDialogState";
import { useEvent } from "components/routes/events/id/EventProvider";

export const LeaderboardButton = ({ heat, primaryButton, closeDrawer }) => (
    <LoadingModalWithTrigger onClose={closeDrawer} isLeaderboard={heat.getIn(["config", "has_leaderboard"])} heat={{ id: heat.get("id"), eventDivision: { id: heat.get("event_division_id") }, roundPosition: heat.get("round_position") }}>
        {primaryButton === "leaderboard" ? <Button variant="outlined"><T>Leaderboard</T></Button> : <ListItemButton><ListItemIcon><FormatListNumbered /></ListItemIcon><ListItemText><T>Leaderboard</T></ListItemText></ListItemButton>}
    </LoadingModalWithTrigger>
);

export const EditHeatToolbar = ({ eventId, heat, renderPrint }) => {
    const pendingRides = useHasPendingRides(heat);
    const print = useCallback(() => {
        setTimeout(window.print, 500); // wait for drawer to get out of the way
        closeDrawer();
    }, []);
    const [isOpen, openDrawer, closeDrawer] = useDialogState();
    const event = useEvent();

    if (!heat) return null;

    const isScheduleDisabled = event.getIn(["config", "disable_schedule"]);

    let primaryButton = "leaderboard";
    if (isScheduleDisabled && !heat.get("end_time")) primaryButton = "racing";
    if (pendingRides > 0) primaryButton = "approve";

    const buttons = {
        racingUndoStart: isScheduleDisabled && !!heat.get("start_time") && !heat.get("end_time") && <RacingUndoStart closeDrawer={closeDrawer} eventId={eventId} heatId={heat.get("id")} />,
        leaderboard: <LeaderboardButton heat={heat} primaryButton={primaryButton} closeDrawer={closeDrawer} />,
        racing: isScheduleDisabled && <RacingToolbar closeDrawer={closeDrawer} eventId={eventId} heat={heat}/>,
        approve: pendingRides > 0 && <ApproveAllRidesButton eventId={eventId} heatId={heat.get("id")} pendingRides={pendingRides}/>,
        print: renderPrint && <ListItemButton onClick={print}><ListItemIcon><Print/></ListItemIcon><ListItemText><T>Print</T></ListItemText></ListItemButton>
    };

    const secondaryButtons = Object.keys(buttons).filter(k => k !== primaryButton && buttons[k]);

    return (
        <>
            {buttons[primaryButton]}
            {secondaryButtons.length > 0 && (
                <EditHeatToolbarSecondaryMenu
                    isOpen={isOpen}
                    openDrawer={openDrawer}
                    closeDrawer={closeDrawer}
                    items={secondaryButtons.map(k => buttons[k])}
                />
            )}
        </>
    );
};
