import classnames from "classnames";
import { LinearProgress, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(() => ({
    progress: {
        position: "absolute",
        width: "100%"
    }
}));
export const ProgressListItem = ({ children, inProgress, startIcon, ...rest }) => {
    const classes = useStyles();

    return (
        <ListItemButton disabled={inProgress} className={classnames({ hideText: inProgress })} { ...rest}>
            {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
            <ListItemText primary={
                <span>
                    {children}
                    {inProgress && <div className={classes.progress}><LinearProgress/></div>}
                </span>}/>
        </ListItemButton>
    );
};
