import { useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import { CloudDownload, ContentCopy, OpenInNew } from "@mui/icons-material";
import { T } from "../../util/t";

import { useFacebook } from "components/providers/FacebookProvider";

import * as NotificationActions from "../../../actions/notification";
import { useIsMobile } from "../../../hooks/useIsMobile";

const IconOrButton = ({ text, icon, isIcon, ...rest }) =>
    isIcon ?
        <IconButton size="small" {...rest}>{icon}</IconButton> :
        <Button variant="outlined" {...rest} startIcon={icon}><T>{text}</T></Button>;


const Actions = ({ download, link, copyToClipboard, onlyIcons }) => {
    return (
        (download
            ? <IconOrButton className="link-item-button" component="a" href={link} target="_blank" icon={<CloudDownload fontSize="small"/>} text="Download" isIcon={onlyIcons}/>
            : document.queryCommandSupported("copy") && <IconOrButton className="link-item-button" onClick={copyToClipboard} icon={<ContentCopy fontSize="small"/>} text="Copy link" isIcon={onlyIcons}/>
        ) || null
    );
};

export const EventLinkItem = ({ title, link, share = false, download = false }) => {
    const dispatch = useDispatch();
    const FB = useFacebook();
    const input = useRef();
    const isMobile = useIsMobile();

    const copyToClipboard = () => {
        input.current.focus();
        input.current.select();
        document.execCommand("copy");
        dispatch(NotificationActions.success("Copied to clipboard!"));
    };

    const doShare = () => FB.ui({ method: "share", href: `https://www.liveheats.com${link}` });

    return (
        <div className="event-link-item">
            <Typography className="label" variant="label1" component="p"><T>{title}</T></Typography>

            <div className="input-container">
                <Link to={link} target="_blank">
                    <input type="text" readOnly={true} value={`https://liveheats.com${link}`} ref={input}/>
                </Link>

                {share && FB && <IconButton className="link-item-button" size="small" onClick={doShare}><OpenInNew fontSize="small"/></IconButton>}
                {isMobile && <Actions download={download} link={link} copyToClipboard={copyToClipboard} onlyIcons />}
            </div>

            {!isMobile && <Actions download={download} link={link} copyToClipboard={copyToClipboard} />}
        </div>
    );
};

EventLinkItem.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    share: PropTypes.bool,
    download: PropTypes.bool
};
