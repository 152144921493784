import { useRef } from "react";

export const useCancellableDebounce = (func, delay, cancel = true) => {
    const timeout = useRef();

    return (...args) => {
        if (cancel) clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            func(...args);
        }, delay);
    };
};
